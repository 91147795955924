import { fieldsFormPlantLeft, optionsCareComplexity } from '../fieldsFormPlant';
import Select from 'react-select';
import { Input } from 'reactstrap';
import React from 'react';
import { Controller } from 'react-hook-form';
import {
  displayTextError,
  isNotEmpty,
  selectThemeColorsCustom,
} from '../../../functions/helpers';

export const FormFieldsLeft = ({ control, errors }) => {
  return (
    <>
      {fieldsFormPlantLeft.map((item, index) => {
        const { label, name, type } = item;
        if (type === 'select')
          return (
            <div key={index} className={'w-100'}>
              <div className={'d-flex justify-content-between'}>
                <p className={`m-0 text-white text-uppercase`}>{label}</p>
                <p
                  className={'align-self-end m-0 text-danger'}
                  style={{ height: '1.5rem' }}
                >
                  {errors[name]?.message || ''}
                </p>
              </div>

              <Controller
                name={name}
                control={control}
                rules={{
                  required: true,
                }}
                render={({ field }) => (
                  <Select
                    {...field}
                    isSearchable={false}
                    placeholder={'Выберите'}
                    maxMenuHeight={500}
                    theme={selectThemeColorsCustom}
                    className={`basic-multi-select mb-2 ${
                      isNotEmpty(errors)
                        ? errors[name]
                          ? 'border-danger'
                          : 'border-success'
                        : 'border-1'
                    }`}
                    classNamePrefix={'select'}
                    isClearable={false}
                    options={optionsCareComplexity}
                  />
                )}
              />
            </div>
          );
        return (
          <div key={index} className={'w-100'}>
            <div className={'d-flex justify-content-between'}>
              <p className={`m-0 text-white text-uppercase`}>{label}</p>
              <p
                className={'align-self-end m-0 text-danger'}
                style={{ height: '1.5rem' }}
              >
                {errors[name] ? displayTextError(errors[name]) : ''}
              </p>
            </div>
            <Controller
              name={name}
              control={control}
              rules={{validate: {positive: v => parseInt(v) !== 0}}}
              // rules={{ required: true, pattern: /^\d+$/ }}
              render={({ field }) => (
                <Input
                  {...field}
                  placeholder={
                    type === 'number' ? 'Введите число' : 'Введите текст'
                  }
                  className={`card-input mb-2 ${
                    isNotEmpty(errors)
                      ? errors[name]
                        ? 'border-danger'
                        : 'border-success'
                      : 'border-1'
                  }`}
                />
              )}
            />
          </div>
        );
      })}
    </>
  );
};
