import React from 'react'
import {NavLink} from 'react-router-dom'

export const Nav = () => (
    <nav className={'d-flex justify-content-between'}>
        <NavLink activeStyle={{color: '#FFF'}} to='/plants'> База растений </NavLink>
        <NavLink activeStyle={{color: '#FFF'}} to='/pests'> База вредителей (болезней)</NavLink>
        <NavLink activeStyle={{color: '#FFF'}} to='/benefits'> База полезных свойств</NavLink>
        <NavLink activeStyle={{color: '#FFF'}} to='/users'> Клиентская база</NavLink>
    </nav>
)