import iconRu from '@src/assets/images/langRu.png';
import iconEn from '@src/assets/images/langEng.png';
import { NavLink, useHistory, useLocation } from 'react-router-dom';
import {
  Button,
  ButtonDropdown,
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
} from 'reactstrap';
import { useState } from 'react';
import { useSelector } from 'react-redux';
import { languageDispatcher } from '../../../store/dispatchers';
import { useContext } from 'react';
import { AuthContext } from '../../../../utility/context/AuthContext';

export const languageOptions = [
  { icon: iconEn, label: 'English', id: 'en' },
  { icon: iconRu, label: 'Русский', id: 'ru' },
];

export const getPagesOptions = (path, history) => {

  if (path?.includes('/plants')) {
    return { link: '/plants/newPlant', text: 'растение', search: 'растению', table: 'растения' };
  } else if (path?.includes('/pests')) {
    return { link: '/pests/newPest', text: 'вредителя', search: 'вредителю', table: 'вредителя' };
  } else if (path?.includes('/benefits')) {
    return { link: '/benefits/newBenefit', text: 'пользу', search: 'пользе', table: 'пользы' };
  } else {
    history.push('/plants')
    return { link: '/plants/newPlant', text: 'растение', search: 'растению', table: 'растения' };
  }
};

export const checkLanguage = (languageCurrent, languageAll) => {
  return languageAll.filter((item) => item.id === languageCurrent)[0];
};

export const NavRight = () => {
  const { pathname } = useLocation();
  const history = useHistory();
  const isUsers = pathname?.includes('users');
  const [dropdownOpen, setDropdownOpen] = useState(false);
  const language = useSelector((state) => state.language);
  const { logOut } = useContext(AuthContext);
  const { icon, label } = checkLanguage(language, languageOptions);

  const toggleDropdown = () => {
    setDropdownOpen(!dropdownOpen);
  };

  return (
    <div className={'w-50 d-flex justify-content-end align-items-center'}>
      <div className={'d-flex me-2 align-items-center'}>
        <div className={'menu-language'}>
          <ButtonDropdown isOpen={dropdownOpen} toggle={toggleDropdown}>
            <DropdownToggle className={'d-flex align-items-center'}>
              <img src={icon} alt={label} />
              <div className={'ms-50 me-1 width-50 text-white'}>{label}</div>
            </DropdownToggle>
            <DropdownMenu>
              {languageOptions.map((item, index) => {
                return (
                  <DropdownItem
                    onClick={() => languageDispatcher(item.id)}
                    key={index}
                    tag="span"
                  >
                    <img src={item.icon} alt={item.label} />
                    <span className={'ms-1 text-white'}>{item.label}</span>
                  </DropdownItem>
                );
              })}
            </DropdownMenu>
          </ButtonDropdown>
        </div>
      </div>
      {!isUsers && pathname !== '/' && pathname !== '' && (
        <NavLink
          to={getPagesOptions(pathname, history)?.link}
          className={'btn btn-success br-10'}
        >
          {`Добавить ${getPagesOptions(pathname, history)?.text}`}
        </NavLink>
      )}

      <Button className={'br-10 ms-2'} onClick={logOut}>
        Выйти
      </Button>
    </div>
  );
};
