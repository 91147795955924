import './Table.scss';
import { Button, Table } from 'reactstrap';
import { RowTable } from './rowTable/RowTable';
import React, { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import { InputSearch } from '../inputSearch/InputSearch';
import { selectThemeColorsCustom } from '../../../functions/helpers';
import { useSelector } from 'react-redux';
import { Loader } from '../../../components/loader/Loader';
import {
  getLimitedBenefits,
  getLimitedPests,
  getLimitedPlants,
  searchBenefits,
  searchPests,
  searchPlants,
} from '../../../../api/requests';
import { getPagesOptions } from '../../../components/header/navRight/navRight';

import Select from 'react-select';

const pageSizeOptions = [
  { value: 10, label: 10 },
  { value: 25, label: 25 },
  { value: 50, label: 50 },
];

const getPathUsersRequest = (pathname) => {
  switch (pathname) {
    case '/users/plants':
      return '/plants/suggestions';
    case '/users/pests':
      return '/pests/suggestions';
    default:
      return pathname;
  }
};

const requestsForPages = (searchValue, limit) => ({
  '/plants': searchValue
    ? searchPlants(searchValue, limit)
    : getLimitedPlants(limit),
  '/pests': searchValue
    ? searchPests(searchValue, limit)
    : getLimitedPests(limit),
  '/benefits': searchValue
    ? searchBenefits(searchValue, limit)
    : getLimitedBenefits(limit),
});

export const TableBasic = () => {
  const [data, setData] = useState([]);
  const [page, setPage] = useState(1);
  const [prevAmount, setPrevAmount] = useState(0);
  const [searchValue, setSearchValue] = useState('');
  const [isLoader, setIsLoader] = useState(true);
  const location = useLocation();
  const { pathname } = location;
  const [pageSize, setPageSize] = useState({ value: 10, label: 10 });
  const language = useSelector((state) => state.language);

  useEffect(() => {
    setPrevAmount(data.length);
    setIsLoader(true);
    const promise = new Promise(function (resolve) {
      const result = requestsForPages(searchValue, pageSize.value * page)[
        pathname
      ];

      resolve(result);
    });

    promise.then((value) => {
      const data = [];
      value.forEach((doc) => {
        data.push({ id: doc.id, data: doc.data() });
      });

      setData(data);
      setIsLoader(false);
    });
  }, [pathname, searchValue, pageSize, page]);

  // useEffect(() => {
  //   data?.sort((a, b) => {
  //     const nameA = pathname.includes('/plants')
  //       ? a.data?.localizedVariety?.[language]?.toUpperCase() ||
  //         a.data?.variety?.toUpperCase()
  //       : a.data?.localizedName?.[language]?.toUpperCase() ||
  //         a.data?.name?.toUpperCase();
  //     const nameB = pathname.includes('/plants')
  //       ? b.data?.localizedVariety?.[language]?.toUpperCase() ||
  //         b.data?.variety?.toUpperCase()
  //       : b.data?.localizedName?.[language]?.toUpperCase() ||
  //         b.data?.name?.toUpperCase();
  //     if (nameA < nameB) return -1;
  //     if (nameA > nameB) return 1;
  //     return 0;
  //   });
  //   setData(data);
  // }, [data, language]);

  const handleChangeSearch = (e) => {
    e ? setSearchValue(e.current.value) : setSearchValue('');
  };

  return (
    <>
      {isLoader && <Loader />}

      <InputSearch onChange={handleChangeSearch} />

      <Table responsive className={'mb-0'}>
        <thead className={'table-dark'}>
          <tr className={''}>
            <th>ID {getPagesOptions(pathname).table}</th>
            {!pathname.includes('benefits') && <th>Фото</th>}
            <th>Наименование</th>
            <th>{pathname.includes('/users') ? 'статус' : ''}</th>
            <th>Действия</th>
          </tr>
        </thead>
        <tbody>
          {data?.map((item, index) => (
            <RowTable
              item={item}
              key={index}
              path={pathname}
              language={language}
            />
          ))}
        </tbody>
      </Table>
      {data?.length > 0 && (
        <>
          <div className={'table-div'}></div>{' '}
          <div className="mt-2 pb-4 d-flex justify-content-between">
            {!searchValue && (
              <>
                <Select
                  options={pageSizeOptions}
                  value={pageSize}
                  onChange={(v) => {
                    setPageSize(v);
                  }}
                  classNamePrefix={'select'}
                  theme={selectThemeColorsCustom}
                  className={`basic-multi-select border-1 width-100`}
                />
                <div className={'d-flex align-items-center gap-2'}>
                  <div className={'text-white'}>Элементов на странице: {data.length || 0}</div>
                  <Button
                    disabled={data.length === prevAmount}
                    type={'button'}
                    color={'success'}
                    onClick={() => setPage((prev) => prev + 1)}
                  >
                    Показать еще
                  </Button>
                </div>
              </>
            )}
            {/* <Button type={'button'}>Назад</Button>
              <Button type={'button'} color={'success'}>
                Вперед
              </Button> */}
            {/* <ReactPaginate
                nextLabel={' >'}
                onPageChange={(e) => setPage(e.selected + 1)}
                pageRangeDisplayed={3}
                marginPagesDisplayed={2}
                pageCount={
                  Math.ceil(pagesCount) > 1
                    ? (pagesCount)
                    : 0
                }
                previousLabel={'< '}
                pageClassName={'page-item'}
                pageLinkClassName={'page-link'}
                previousClassName={'page-item'}
                previousLinkClassName={'page-link'}
                nextClassName={'page-item'}
                nextLinkClassName={'page-link'}
                breakLabel={'...'}
                breakClassName={'page-item'}
                breakLinkClassName={'page-link'}
                containerClassName={'pagination'}
                activeClassName={'active'}
                renderOnZeroPageCount={null}
              /> */}
          </div>
        </>
      )}
    </>
  );
};
