import {Toaster} from 'react-hot-toast'

export const ToasterCustom = () => {
  return (<Toaster position={'top-right'}
           reverseOrder={false}
           gutter={8}
           containerClassName={''}
           containerStyle={''}
           toastOptions={{
             // Define default options
             className: '',
             duration: 5000,
             style: {
               background: '#fff',
               color: '#363636',
               padding: '10px',
               gap: '10px'
             },
             // Default options for specific types
             success: {
               duration: 3000,
               theme: {
                 primary: 'green',
                 secondary: 'black'
               }
             }
           }}/>)
}