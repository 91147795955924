import { Controller } from 'react-hook-form';
import { displayTextError, isNotEmpty } from '../../../functions/helpers';
import { Input } from 'reactstrap';

export const PlantVariety = ({ language, errors, control }) => {
  return (
    <>
      <div className={'d-flex justify-content-between'}>
        <p className={'text-white m-0 text-uppercase'}>ВИД растения</p>
        {/* <p
          className={'align-self-end m-0 text-danger'}
          style={{ height: '1.5rem' }}
        >
          {errors?.[`variety_${language}`]
            ? displayTextError(errors[`variety_${language}`])
            : ''}
        </p> */}
      </div>

      <div className="d-flex mb-1">
        <div className="w-50 me-1">
          <div className={'d-flex justify-content-between'}>
            <p className={'text-white m-0'}>RU</p>
            <p
              className={'align-self-end m-0 text-danger'}
              style={{ height: '1.5rem' }}
            >
              {errors?.[`variety__ru`]
                ? displayTextError(errors[`variety__ru`])
                : ''}
            </p>
          </div>
          <Controller
            // key={language}
            // name={`variety_${language}`}
            name={`variety_ru`}
            control={control}
            rules={{
              required: true,
              maxLength: 50,
              validate: {
                notEmpty: (v) => String(v).trim().length > 0,
              },
              pattern: /[^\!@#$%^&*~]$/,
            }}
            render={({ field }) => (
              <Input
                {...field}
                placeholder={'Введите название растения'}
                className={`card-input ${
                  isNotEmpty(errors)
                    ? // ? errors[`variety_${language}`]
                      errors[`variety_ru`]
                      ? 'border-danger'
                      : 'border-success'
                    : 'border-1'
                }`}
              />
            )}
          />
        </div>
        <div className="w-50">
          <div className={'d-flex justify-content-between'}>
            <p className={'text-white m-0'}>EN</p>
            <p
              className={'align-self-end m-0 text-danger'}
              style={{ height: '1.5rem' }}
            >
              {errors?.[`variety_en`]
                ? displayTextError(errors[`variety_en`])
                : ''}
            </p>
          </div>
          <Controller
            // key={language}
            // name={`variety_${language}`}
            name={`variety_en`}
            control={control}
            rules={{
              required: true,
              maxLength: 50,
              validate: {
                notEmpty: (v) => String(v).trim().length > 0,
              },
              pattern: /[^\!@#$%^&*~]$/,
            }}
            render={({ field }) => (
              <Input
                {...field}
                placeholder={'Введите название растения'}
                className={`card-input ${
                  isNotEmpty(errors)
                    ? // ? errors[`variety_${language}`]
                      errors[`variety_en`]
                      ? 'border-danger'
                      : 'border-success'
                    : 'border-1'
                }`}
              />
            )}
          />
        </div>
      </div>

      <div className={'d-flex justify-content-between'}>
        <p className={'text-white m-0 text-uppercase'}>КОД РАСТЕНИЯ</p>
        <p
          className={'align-self-end m-0 text-danger'}
          style={{ height: '1.5rem' }}
        >
          {errors?.varietyCode ? displayTextError(errors.varietyCode) : ''}
        </p>
      </div>
      <Controller
        name={'varietyCode'}
        control={control}
        rules={{
          required: true,
          maxLength: 50,
          validate: {
            notEmpty: (v) => String(v).trim().length > 0,
          },
        }}
        render={({ field }) => (
          <Input
            {...field}
            placeholder={'Введите код растения'}
            className={`card-input mb-1 ${
              isNotEmpty(errors)
                ? errors.varietyCode
                  ? 'border-danger'
                  : 'border-success'
                : 'border-1'
            }`}
          />
        )}
      />
    </>
  );
};
