import { useForm } from 'react-hook-form';
import { Img } from '../../components/img/Img';
import { Button, Spinner } from 'reactstrap';
import React, { useEffect, useState } from 'react';
import { FormFieldsLeft } from './formFieldsLeft/FormFieldsLeft';
import { FormFieldsRight } from './formFieldsRight/FormFieldsRight';
import { useSelector } from 'react-redux';
import { checkDefaultMultiSelect } from '../../functions/helpers';
import { PestsSelect } from './plantFields/PestsSelect';
import { Link, useHistory } from 'react-router-dom';
import { Loader } from '../../components/loader/Loader';
import { ToasterCustom } from '../../components/toster/Toaster';
import { toast } from 'react-hot-toast';
import {
  checkLanguage,
  languageOptions,
} from '../../components/header/navRight/navRight';
import { storage } from '../../../configs/firebase';
import {
  createPlant,
  deletePlant,
  getAllBenefits,
  getAllPests,
  getPlantInfo,
  updatePlant,
} from '../../../api/requests';
import { uploadBytes, ref, getDownloadURL } from 'firebase/storage';
import { CoverPhoto } from '../../components/coverPhoto/CoverPhoto';
import { ModalBasic } from '../../components/modal/Modal';
import {
  optionsCareComplexity,
  optionsReproduction,
  optionsSunRelation,
} from './fieldsFormPlant';
import {
  DeleteModalBody,
  DeleteModalFooter,
} from '../../components/modal/modalComponents/ModalComponents';
import { BenefitsSelect } from './plantFields/BenefitsSelect';
import { PlantVariety } from './plantFields/PlantVariety';
import { PlantDescription } from './plantFields/PlantDescription';

export const descriptionMaxLength = 1500;

export const Plant = (props) => {
  const history = useHistory();
  const plantId = props.match.params.id;
  const isUsersPlant = props.match.path.includes('users');
  const language = useSelector((state) => state.language);
  const { icon, label } = checkLanguage(language, languageOptions);

  const [plantData, setPlantData] = useState(null);
  const [isLoader, setIsLoader] = useState(false);
  const [photo, setPhoto] = useState(null);
  const [coverPhoto, setCoverPhoto] = useState(null);
  const [isShowModal, setIsShowModal] = useState(false);
  const [allPestsOptions, setAllPestsOptions] = useState([]);
  const [allBenefitsOptions, setAllBenefitsOptions] = useState([]);
  const [deletePhotos, setDeletePhotos] = useState(!plantId);
  const [isSavingPlant, setIsSavingPlant] = useState(false);

  const defaultValues = {
    variety_en: plantId
      ? plantData?.localizedVariety?.en || plantData?.variety
      : '',
    variety_ru: plantId
      ? plantData?.localizedVariety?.ru || plantData?.variety
      : '',
    varietyCode: plantId ? plantData?.varietyCode : '',
    careComplexity: plantId
      ? optionsCareComplexity.filter(
          (option) => option.value === plantData?.careComplexity
        )[0]
      : null,
    wateringSummer: plantId ? plantData?.wateringSummer : '',
    wateringWinter: plantId ? plantData?.wateringWinter : '',
    sprayingSummer: plantId ? plantData?.sprayingSummer : '',
    sprayingWinter: plantId ? plantData?.sprayingWinter : '',
    feedingSummer: plantId ? plantData?.feedingSummer : '',
    feedingWinter: plantId ? plantData?.feedingWinter : '',
    pruning_ru: plantId
      ? plantData?.localizedPruning.ru || plantData?.pruning
      : '',
    pruning_en: plantId
      ? plantData?.localizedPruning.en || plantData?.pruning
      : '',
    minTempSummer: plantId ? plantData?.minTempSummer : '',
    maxTempSummer: plantId ? plantData?.maxTempSummer : '',
    minTempWinter: plantId ? plantData?.minTempWinter : '',
    maxTempWinter: plantId ? plantData?.maxTempWinter : '',
    plantingTime_ru: plantId
      ? plantData?.localizedPlantingTime?.ru || plantData?.plantingTime
      : '',
    plantingTime_en: plantId
      ? plantData?.localizedPlantingTime?.en || plantData?.plantingTime
      : '',
    reproduction: plantId
      ? checkDefaultMultiSelect(optionsReproduction, plantData?.reproduction)
      : [],
    sunRelation: plantId
      ? optionsSunRelation.filter(
          (option) => option.value === plantData?.sunRelation
        )[0]
      : null,
    description_ru: plantId
      ? plantData?.localizedDescription?.ru || plantData?.description
      : '',
    description_en: plantId
      ? plantData?.localizedDescription?.en || plantData?.description
      : '',
    pests: plantId
      ? checkDefaultMultiSelect(allPestsOptions, plantData?.pests)
      : [],
    benefits: plantId
      ? checkDefaultMultiSelect(allBenefitsOptions, plantData?.benefits)
      : [],
  };

  const {
    control,
    handleSubmit,
    reset,
    setValue,
    setFocus,
    formState: { errors, dirtyFields, isDirty },
  } = useForm({ defaultValues });

  useEffect(() => {
    if (plantId) {
      setIsLoader(true);
      const plantInfoPromise = new Promise(function (resolve) {
        const result = getPlantInfo(plantId);
        resolve(result);
      });

      plantInfoPromise.then((value) => {
        setPlantData(value);
        setPhoto(value.photo);
        setCoverPhoto(value.coverPhoto);
        setIsLoader(false);
      });
    } else {
      setPlantData(null);
      reset();
      setDeletePhotos(true);
      setIsLoader(false);
    }
  }, [plantId]);

  useEffect(() => {
    setIsLoader(true);
    const pestsPromise = new Promise(function (resolve) {
      const result = getAllPests();
      resolve(result);
    });

    pestsPromise.then((value) => {
      const selectOptions = value
        .map(({ id, data }) => ({
          value: id,
          label: data?.name,
        }))
        .sort((a, b) => {
          const nameA = a.label.toLowerCase();
          const nameB = b.label.toLowerCase();
          if (nameA < nameB) return -1;
          if (nameA > nameB) return 1;
          return 0;
        });
      setAllPestsOptions(selectOptions);
      setIsLoader(false);
    });

    const benefitsPromise = new Promise(function (resolve) {
      const result = getAllBenefits();
      resolve(result);
    });

    benefitsPromise.then((value) => {
      const selectOptions = value
        .map(({ id, data }) => ({
          value: id,
          label: data?.name,
        }))
        .sort((a, b) => {
          const nameA = a.label.toLowerCase();
          const nameB = b.label.toLowerCase();
          if (nameA < nameB) return -1;
          if (nameA > nameB) return 1;
          return 0;
        });
      setAllBenefitsOptions(selectOptions);
      setIsLoader(false);
    });
  }, []);

  useEffect(() => {
    reset(defaultValues);
  }, [plantData, allPestsOptions, allBenefitsOptions]);

  useEffect(() => {
    if (!plantId) {
      setValue(`variety_${language}`, '');
      setValue(`pruning_${language}`, '');
      setValue(`plantingTime_${language}`, '');
      setValue(`description_${language}`, '');
    }
  }, [language, plantId]);

  const onSubmit = async (data) => {
    setDeletePhotos(false);
    setIsSavingPlant(true);
    if (!photo || !coverPhoto) {
      toast.error('Проверьте, добавили ли вы изображение растения и обложку!', {
        autoClose: 15000,
      });
      setIsSavingPlant(false);
      return;
    }

    const objData = {};

    if (plantId) {
      if (photo !== plantData.photo) {
        try {
          const plantsRef = ref(storage, `plants/${photo.name}`);
          await uploadBytes(plantsRef, photo).then((snapshot) =>
            getDownloadURL(snapshot.ref).then((url) => {
              objData.photo = url;
            })
          );
        } catch (e) {
          toast.error(e);
          setIsSavingPlant(false);
        }
      }

      if (coverPhoto !== plantData.coverPhoto) {
        try {
          const plantsRef = ref(storage, `plants/${coverPhoto.name}`);
          await uploadBytes(plantsRef, coverPhoto).then((snapshot) =>
            getDownloadURL(snapshot.ref).then((url) => {
              objData.coverPhoto = url;
            })
          );
        } catch (e) {
          toast.error(e);
          setIsSavingPlant(false);
        }
      }

      if (isDirty) {
        Object.keys(dirtyFields).map((item) => {
          if (Array.isArray(data[item])) {
            objData[item] = data[item].map((_item) => _item.value);
          } else if (item === 'careComplexity' || item === 'sunRelation') {
            objData[item] = data[item].value;
          } else if (!isNaN(+data[item])) {
            if (
              (item === 'feedingSummer' ||
                item === 'feedingWinter' ||
                item === 'sprayingSummer' ||
                item === 'sprayingWinter' ||
                item === 'wateringSummer' ||
                item === 'wateringWinter') &&
              data[item].trim() === ''
            ) {
              objData[item] = null;
            } else {
              objData[item] = +data[item];
            }
          } else if (
            item.includes('description') ||
            item.includes('plantingTime') ||
            item.includes('pruning') ||
            (item.includes('variety') && !item.includes('Code'))
          ) {
            const splitName = item.split('_');
            const hightFirstLetterName =
              splitName[0][0].toUpperCase() + splitName[0].slice(1);
            const lang = splitName[1];

            objData[`localized${hightFirstLetterName}.${lang}`] = data[item];
            if (lang === 'ru') {
              objData[splitName[0]] = data[item];
            }
          } else {
            objData[item] = data[item];
          }
        });
      }

      try {
        await updatePlant(plantId, objData);
        toast.success('Изменения сохранены!');
      } catch (e) {
        toast.error('Не удалось внести изменения!');
        console.log(e);
      }
    } else {
      if (photo) {
        try {
          const plantsRef = ref(storage, `plants/${photo.name}`);
          await uploadBytes(plantsRef, photo).then((snapshot) =>
            getDownloadURL(snapshot.ref).then((url) => {
              objData.photo = url;
            })
          );
        } catch (e) {
          toast.error(e);
          setIsSavingPlant(false);
        }
      }

      if (coverPhoto) {
        try {
          const plantsRef = ref(storage, `plants/${coverPhoto.name}`);
          await uploadBytes(plantsRef, coverPhoto).then((snapshot) =>
            getDownloadURL(snapshot.ref).then((url) => {
              objData.coverPhoto = url;
            })
          );
        } catch (e) {
          toast.error(e);
          setIsSavingPlant(false);
        }
      }

      Object.keys(data).map((item) => {
        if (Array.isArray(data[item])) {
          objData[item] = data[item].map((_item) => _item.value);
        } else if (item === 'careComplexity' || item === 'sunRelation') {
          objData[item] = data[item].value;
        } else if (!isNaN(+data[item])) {
          if (
            (item === 'feedingSummer' ||
              item === 'feedingWinter' ||
              item === 'sprayingSummer' ||
              item === 'sprayingWinter' ||
              item === 'wateringSummer' ||
              item === 'wateringWinter') &&
            data[item].trim() === ''
          ) {
            objData[item] = null;
          } else {
            objData[item] = +data[item];
          }
        } else if (
          item.includes('description') ||
          item.includes('plantingTime') ||
          item.includes('pruning') ||
          (item.includes('variety') && !item.includes('Code'))
        ) {
          const splitName = item.split('_');
          const hightFirstLetterName =
            splitName[0][0].toUpperCase() + splitName[0].slice(1);
          const lang = splitName[1];
          if (objData[`localized${hightFirstLetterName}`]) {
            objData[`localized${hightFirstLetterName}`][lang] = data[item];
          } else {
            objData[`localized${hightFirstLetterName}`] = {
              [lang]: data[item],
            };
          }
          objData[splitName[0]] =
            objData[`localized${hightFirstLetterName}`].ru;
        } else {
          objData[item] = data[item];
        }
      });

      // if (
      //   !objData?.localizedDescription?.en ||
      //   !objData?.localizedPruning?.en ||
      //   !objData?.localizedVariety?.en ||
      //   !objData?.localizedDescription?.ru ||
      //   !objData?.localizedPruning?.ru ||
      //   !objData?.localizedVariety?.ru
      // ) {
      //   toast.error('Введите значения на всех языках!', { autoClose: 15000 });
      //   setIsSavingPlant(false);
      //   return;
      // }

      try {
        await createPlant(objData);
        reset();
        setDeletePhotos(true);
        toast.success('Растение добавлено!');
      } catch (e) {
        toast.error('Ошибка добавления!');
        console.log(e);
      }
    }

    setIsSavingPlant(false);
  };

  const onDeletePlant = async () => {
    try {
      await deletePlant(plantId);
      setIsShowModal(false);
      history.push('/plants');
    } catch (e) {
      console.log(e);
    }
  };

  if (isLoader) return <Loader />;

  return (
    <>
      <form className={'card p-2'}>
        <CoverPhoto
          photo={coverPhoto}
          setPhoto={setCoverPhoto}
          deletePhotos={deletePhotos}
        />
        <ToasterCustom />
        <div
          className={
            'd-flex justify-content-between border-bottom-secondary pb-2'
          }
        >
          <Img photo={photo} setPhoto={setPhoto} deletePhotos={deletePhotos} setDeletePhotos={setDeletePhotos}/>
          {plantId && (
            <Button
              className={'delete-button'}
              color={'danger'}
              onClick={() => {
                setIsShowModal(true);
              }}
              outline
            >
              {'Удалить'}
            </Button>
          )}
        </div>
        <div className={'d-flex align-items-center'}>
          <h2 className={'my-2 card-subtitle'}>ОБЩАЯ ИНФОРМАЦИЯ</h2>
          <div className={'ms-5'}>
            <img className={'me-50'} src={icon} alt={'язык'} />
            <span className={'text-white'}>{label}</span>
          </div>
        </div>

        <PlantVariety language={language} errors={errors} control={control} />

        <div
          className={
            'd-flex justify-content-between mb-2 font-small-3'
          }
        >
          <div className={'w-50 pe-4'}>
            <FormFieldsLeft control={control} errors={errors} />
          </div>
          <div className={'w-50'}>
            <FormFieldsRight control={control} errors={errors} />
          </div>
        </div>

        <PlantDescription
          language={language}
          control={control}
          errors={errors}
          plantData={plantData}
        />

        <PestsSelect
          errors={errors}
          control={control}
          data={plantData?.pests}
          optionsSelect={allPestsOptions}
        />

        <BenefitsSelect
          errors={errors}
          control={control}
          data={plantData?.benefits}
          optionsSelect={allBenefitsOptions}
        />
        <div className={'mt-4'}>
          <Button
            type={'button'}
            onClick={handleSubmit(onSubmit)}
            color={'success'}
            className={'br-10 me-2'}
            disabled={isSavingPlant}
          >
            {isSavingPlant ? <Spinner size={'sm'} /> : 'Сохранить'}
          </Button>
          <Link to={'/plants'} className={'br-10 btn cancel'}>
            {'Отмена'}
          </Link>
        </div>
      </form>
      <ModalBasic
        setIsShowModal={setIsShowModal}
        isShowModal={isShowModal}
        body={
          <DeleteModalBody plantName={plantData?.variety} type={'растение'} />
        }
        footer={
          <DeleteModalFooter
            onCancel={() => {
              setIsShowModal(false);
            }}
            onDelete={onDeletePlant}
          />
        }
      />
    </>
  );
};
