import emptyPlant from '@src/assets/images/emptyPlant.png';
import emptyPest from '@src/assets/images/emptyPest.png';
import React, { useEffect, useState } from 'react';
import { whiteTrash } from '../../../assets/images/svg';
import { useLocation } from 'react-router-dom';
import { ModalBasic } from '../modal/Modal';
import { ImageCrop } from '../imageCrop/ImageCrop';

export const getDefaultSrc = (pathname) => {
  if (pathname.includes('/plants')) return emptyPlant;
  if (pathname.includes('/pests')) return emptyPest;
};

export const Img = ({ setPhoto, photo, deletePhotos, setDeletePhotos }) => {
  const { pathname } = useLocation();

  const [isCropped, setIsCropped] = useState(false);
  const [isShowModal, setIsShowModal] = useState(false);
  const [photoState, setPhotoState] = useState(null);
  const src = photoState ? URL.createObjectURL(photoState) : photo;

  useEffect(() => {
    if (deletePhotos) {
      setPhoto(null);
      setPhotoState(null);
    }
  }, [deletePhotos]);

  const onApplyCrop = (cropperRef) => {
    const canvas = cropperRef.current?.getCanvas();
    if (canvas) {
      canvas.toBlob((blob) => {
        if (blob) {
          const myFile = new File([blob], `${Date.now()}.jpeg`, {
            type: blob.type,
          });
          setPhotoState(myFile);
          setPhoto(myFile);
        }
      }, 'image/jpeg');
      setIsCropped(true);
      setIsShowModal(false);
    }
  };

  return (
    <>
      <div className={'img d-flex justify-content-between align-items-center'}>
        <div className={'d-flex align-items-center'}>
          <img
            className={`height-100 width-100 me-2 rounded-circle ${
              src ? 'cursor-pointer' : ''
            }`}
            src={src || getDefaultSrc(pathname)}
            alt={'фото'}
            style={{ objectFit: 'cover' }}
            onClick={() => {
              if (src) setIsShowModal(true);
            }}
          />

          <div className={''}>
            <div className={'mb-1'}>
              <label className={'btn round me-1 new-photo'} color={'success'}>
                Загрузить новое фото
                <input
                  onChange={(e) => {
                    if (e.target.files[0]) {
                      setPhotoState(e.target.files[0]);
                      setPhoto(e.target.files[0]);
                    }
                    setIsCropped(true);
                    setDeletePhotos(false);
                  }}
                  type="file"
                  accept="image/jpeg, image/png, image/gif"
                  className={'visually-hidden'}
                />
              </label>
              <button
                className={'delete-photo'}
                onClick={() => {
                  setPhoto(null);
                  setPhotoState(null);
                }}
                type={'button'}
              >
                {whiteTrash}
              </button>
            </div>
            <span style={{ color: '#BBBBBB' }}>
              JPG, Gif или PNG. Макс. размер файла 20mb
            </span>
          </div>
        </div>
      </div>

      <ModalBasic
        setIsShowModal={setIsShowModal}
        isShowModal={isShowModal}
        body={
          <ImageCrop
            photo={photo}
            onCancel={() => {
              setIsShowModal(false);
            }}
            onApply={onApplyCrop}
            isCropped={isCropped}
            setIsCropped={setIsCropped}
            aspectRatio={1}
            isRound
          />
        }
      />
    </>
  );
};
