import { Button, Input, InputGroup, InputGroupText } from 'reactstrap';
import React, { createRef } from 'react';
import { useLocation } from 'react-router-dom';
import './InputSearch.scss';
import { getPagesOptions } from '../../../components/header/navRight/navRight';

export const InputSearch = ({ onChange }) => {
  const location = useLocation();
  const { pathname } = location;
  const inputRef = createRef(null);
  // const placeholderText = pathname.includes('/pests') ? 'вредителю' : 'растению' //todo: если оставляем поиск, то надо добавить сюда и пользу

  return (
    <div className={'card px-2 py-1 flex-row flex-nowrap'}>
      <InputGroup className="input-group-merge round border-white custom-input-group flex-nowrap">
        <InputGroupText className="custom-input-group-text">
          <svg
            width="25"
            height="24"
            viewBox="0 0 25 24"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              fillRule="evenodd"
              clipRule="evenodd"
              d="M11 18C15.1421 18 18.5 14.6421 18.5 10.5C18.5 6.35786 15.1421 3 11 3C6.85786 3 3.5 6.35786 3.5 10.5C3.5 14.6421 6.85786 18 11 18Z"
              stroke="white"
              strokeWidth="1.5"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
            <path
              d="M21.5 21L16.3 15.8"
              stroke="white"
              strokeWidth="1.5"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
          </svg>
        </InputGroupText>
        <input
          placeholder={`Поиск по ${getPagesOptions(pathname).search}`}
          className={'input full-width'}
          ref={inputRef}
        />
      </InputGroup>
      <div className="d-flex ms-2">
        <Button
          type={'button'}
          color={'success'}
          className="br-10 me-1"
          onClick={() => onChange(inputRef)}
        >
          Искать
        </Button>
        <Button
          type={'button'}
          className="br-10"
          onClick={() => {
            inputRef.current.value = '';
            onChange('');
          }}
        >
          x
        </Button>
      </div>
    </div>
  );
};
