import { useEffect } from 'react';
import { useState } from 'react';
import { Controller, useForm } from 'react-hook-form';
// import { useSelector } from 'react-redux';
import { Link, useHistory } from 'react-router-dom';
import {
  createBenefit,
  deleteBenefit,
  getBenefitInfo,
  updateBenefit,
} from '../../../api/requests';
import { Loader } from 'react-feather';
import { ModalBasic } from '../../components/modal/Modal';
import { Button, Input, Spinner } from 'reactstrap';
import { ToasterCustom } from '../../components/toster/Toaster';
import {
  DeleteModalBody,
  DeleteModalFooter,
} from '../../components/modal/modalComponents/ModalComponents';
// import { displayTextError, isNotEmpty } from '../../functions/helpers';
import toast from 'react-hot-toast';
import { BenefitName } from './benefitFields/BenefitName';

export const Benefit = (props) => {
  const [benefitData, setBenefitData] = useState(null);
  const [isLoader, setIsLoader] = useState(false);
  const [isShowModal, setIsShowModal] = useState(false);
  const [isSavingBenefit, setIsSavingBenefit] = useState(false);
  const benefitId = props.match.params.id;
  const history = useHistory();
  // const language = useSelector((state) => state.language);

  const defaultValues = {
    name_ru: benefitId
      ? benefitData?.localizedName?.ru || benefitData?.name
      : '',
    name_en: benefitId
      ? benefitData?.localizedName?.en || benefitData?.name
      : '',
  };

  const {
    control,
    handleSubmit,
    reset,
    setValue,
    formState: { errors, isDirty },
  } = useForm({ defaultValues });

  useEffect(() => {
    setIsLoader(true);
    if (benefitId) {
      const benefitInfoPromise = new Promise(function (resolve) {
        const result = getBenefitInfo(benefitId);
        resolve(result);
      });

      benefitInfoPromise.then((value) => {
        setBenefitData(value);
        setIsLoader(false);
      });
    } else {
      setBenefitData(null);
      reset();
      setIsLoader(false);
    }
  }, [benefitId]);

  useEffect(() => {
    reset(defaultValues);
  }, [benefitData]);

  const onSubmit = async (data) => {
    setIsSavingBenefit(true);
    const objData = {};

    if (benefitId) {
      if (isDirty) {
        Object.keys(data).map((item) => {
          if (item.includes('description') || item.includes('name')) {
            const splitName = item.split('_');
            const hightFirstLetterName =
              splitName[0][0].toUpperCase() + splitName[0].slice(1);
            const lang = splitName[1];

            objData[`localized${hightFirstLetterName}.${lang}`] = data[item];

            if (lang === 'ru') {
              objData[splitName[0]] = data[item];
            }
          } else {
            objData[item] = data[item];
          }
        });
      }

      try {
        await updateBenefit(benefitId, objData);
        toast.success('Изменения сохранены!');
      } catch (e) {
        toast.error('Не удалось внести изменения!');
        console.log(e);
      }
    } else {
      Object.keys(data).map((item) => {
        if (item.includes('name')) {
          const splitName = item.split('_');
          const hightFirstLetterName =
            splitName[0][0].toUpperCase() + splitName[0].slice(1);
          const lang = splitName[1];
          if (objData[`localized${hightFirstLetterName}`]) {
            objData[`localized${hightFirstLetterName}`][lang] = data[item];
          } else {
            objData[`localized${hightFirstLetterName}`] = {
              [lang]: data[item],
            };
          }
          objData[splitName[0]] =
            objData[`localized${hightFirstLetterName}`].ru;
        } else {
          objData[item] = data[item];
        }
      });

      // if (!objData?.localizedName?.en || !objData?.localizedName?.ru) {
      //   toast.error('Введите значения на всех языках!', { autoClose: 15000 });
      //   setIsSavingBenefit(false);
      //   return;
      // }

      try {
        await createBenefit(objData);
        reset();
        toast.success('Польза добавлена!');
      } catch (e) {
        toast.error('Ошибка добавления!');
        console.log(e);
      }
    }

    setIsSavingBenefit(false);
  };

  // useEffect(() => {
  //   if (!benefitId) {
  //     setValue(`name_${language}`, '');
  //   }
  // }, [language, benefitId]);

  const onDeleteBenefit = async () => {
    try {
      await deleteBenefit(benefitId);
      setIsShowModal(false);
      history.push('/benefits');
    } catch (e) {
      console.log(e);
    }
  };

  if (isLoader) return <Loader />;

  return (
    <>
      <form className={'card p-2'} onSubmit={handleSubmit(onSubmit)}>
        <ToasterCustom />
        <div
          className={
            'd-flex justify-content-end align-items-center border-bottom-secondary pb-2'
          }
        >
          {benefitId && (
            <Button
              className={'delete-button'}
              color={'danger'}
              onClick={() => {
                setIsShowModal(true);
              }}
              outline
            >
              {'Удалить'}
            </Button>
          )}
        </div>
        <h2 className={'my-2 card-subtitle'}>ОБЩАЯ ИНФОРМАЦИЯ</h2>

        <BenefitName control={control} errors={errors}/>

        <div className="mt-3">
          <Button
            color={'success'}
            className={'br-10 me-2'}
            disabled={isSavingBenefit}
          >
            {isSavingBenefit ? <Spinner size={'sm'} /> : 'Сохранить'}
          </Button>
          <Link to={'/benefits'} className={'br-10 btn cancel'}>
            {'Отмена'}
          </Link>
        </div>
      </form>

      <ModalBasic
        setIsShowModal={setIsShowModal}
        isShowModal={isShowModal}
        body={<DeleteModalBody plantName={benefitData?.name} type={'пользу'} />}
        footer={
          <DeleteModalFooter
            onCancel={() => {
              setIsShowModal(false);
            }}
            onDelete={onDeleteBenefit}
          />
        }
      />
    </>
  );
};
