import {createContext, useEffect, useState} from "react";
import Cookies from "js-cookie";
// import {getAuth, onAuthStateChanged} from "firebase/auth";
// import {doc, getDoc} from "../../api/firebase/exports";
// import {firestore, auth} from "../../configs/firebase"

export const AuthContext = createContext({})

export function AuthProvider({children}) {
	const isAuth = () => !!(Cookies.get('token'))
	const getToken = () => Cookies.get('token')
	const removeToken = () => Cookies.remove('token')
	const setToken = token => Cookies.set('token', token, {expires: 7})

	const logOut = () => {
		removeToken()
		setIsAuthState(false)
	}
	
	const logInWtoken = (token) => {
		if (!token) return
		setToken(token)
		setIsAuthState(true)
	}
	
	const [isAuthState, setIsAuthState] = useState(isAuth)
	
	useEffect(() => {
		// const auth = getAuth()
		
    // todo: сейчас эта функция "разлогинивает" (удаляет из куки) токен при перезагрузке страницы. зачем она нужна?
		// onAuthStateChanged(auth, (user) => {
		// 	getDoc(doc(firestore, 'adminAuth', user.uid))
		// 		.then(doc => {
		// 			(!doc.exists() || !doc.data().is_admin)
		// 			&& logOut()
		// 		})
		// 	!user && logOut()
		// }) 

		
		const id = setInterval(() => {
			// const path = window.location.pathname
			if (!Cookies.get('token') /*&& path !== '/login'*/) {
				logOut()
			}
		}, 10000)

		return () => clearInterval(id)
	}, [])
	
	return (
		<AuthContext.Provider value={{isAuthState, setToken, getToken, logOut, logInWtoken,}}>
			{children}
		</AuthContext.Provider>
	);
}
