import { checkQueryParams } from '../layouts/functions/helpers';
import { firebaseConfig, firestore } from '../configs/firebase';
import {
  addDoc,
  collection,
  deleteDoc,
  doc,
  getDoc,
  getDocs,
  query,
  updateDoc,
  where,
  limit,
  orderBy,
  startAfter,
} from './firebase/exports';

export const plantsCollection =
  process.env.REACT_APP_FIREBASE_PLANTS_COLLECTION;
const pestsCollection = process.env.REACT_APP_FIREBASE_PESTS_COLLECTION;
const benefitsCollection = process.env.REACT_APP_FIREBASE_BENEFITS_COLLECTION;

const plantsRef = collection(firestore, plantsCollection);
const pestsRef = collection(firestore, pestsCollection);
const benefitRef = collection(firestore, benefitsCollection);

export const getLimitedPlants = async (limitValue = 10) => {
  const result = query(plantsRef, orderBy('variety'), limit(limitValue));
  const docs = await getDocs(result);
  return docs;
};

export const searchPlants = async (searchValue, limitValue = 10) => {
  const result = query(
    plantsRef,
    where('variety', '==', searchValue),
    limit(limitValue)
  );
  const docs = await getDocs(result);
  return docs;
};

export const getPlantInfo = async (plantId) => {
  const ref = doc(firestore, plantsCollection, plantId);
  const docSnap = await getDoc(ref).then((doc) => doc.data());
  return docSnap;
};

export const createPlant = async (data) => {
  return await addDoc(plantsRef, data);
}; //создаем растение с id, генерируемым ФБ

export const updatePlant = async (plantId, data) => {
  const ref = doc(firestore, plantsCollection, plantId);

  return await updateDoc(ref, data);
};

export const deletePlant = async (plantId) => {
  return await deleteDoc(doc(firestore, plantsCollection, plantId));
};

export const getAllPests = async () => {
  const docs = await getDocs(pestsRef);
  const data = [];
  docs.forEach((doc) => {
    data.push({ id: doc.id, data: doc.data() });
  });
  return data;
}

export const getLimitedPests = async (limitValue = 10) => {
  const result = query(pestsRef, orderBy('name'), limit(limitValue));
  const docs = await getDocs(result);
  return docs;
};

export const searchPests = async (searchValue, limitValue = 10) => {
  const result = query(
    pestsRef,
    where('name', '==', searchValue),
    limit(limitValue)
  );
  const docs = await getDocs(result);
  return docs;
};

export const createPest = async (data) => {
  return await addDoc(pestsRef, data);
};

export const getPestInfo = async (pestId) => {
  const ref = doc(firestore, pestsCollection, pestId);
  const docSnap = await getDoc(ref).then((doc) => doc.data());
  return docSnap;
};

export const updatePest = async (pestId, data) => {
  const ref = doc(firestore, pestsCollection, pestId);

  return await updateDoc(ref, data);
};

export const deletePest = async (pestId) => {
  return await deleteDoc(doc(firestore, pestsCollection, pestId));
};

export const getAllBenefits = async () => {
  const docs = await getDocs(benefitRef);
  const data = [];
  docs.forEach((doc) => {
    data.push({ id: doc.id, data: doc.data() });
  });
  return data;
};

export const getLimitedBenefits = async (limitValue = 10) => {
  const result = query(benefitRef, orderBy('name'), limit(limitValue));
  const docs = await getDocs(result);

  return docs;
};

export const searchBenefits = async (searchValue, limitValue = 10) => {
  const result = query(
    benefitRef,
    where('name', '==', searchValue),
    limit(limitValue)
  );
  const docs = await getDocs(result);
  return docs;
};

export const createBenefit = async (data) => {
  return await addDoc(benefitRef, data);
};

export const getBenefitInfo = async (benefitId) => {
  const ref = doc(firestore, benefitsCollection, benefitId);
  const docSnap = await getDoc(ref).then((doc) => doc.data());
  return docSnap;
};

export const updateBenefit = async (benefitId, data) => {
  const ref = doc(firestore, benefitsCollection, benefitId);

  return await updateDoc(ref, data);
};

export const deleteBenefit = async (benefitId) => {
  return await deleteDoc(doc(firestore, benefitsCollection, benefitId));
};
