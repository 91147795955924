import {Spinner} from 'reactstrap'
import React from 'react'

export const Loader = () => {

  return (
    <div className={'d-flex h-100 w-100 position-absolute left-0 justify-content-center align-items-center bg-secondary bg-opacity-50'}>
      <Spinner color={'success'} size={'lg'}/>
    </div>
  )
}