import React from 'react';
import { BrowserRouter } from 'react-router-dom';
import { Header } from '../../components/header';
import { Routes } from '../../../router/Routes';

export const Main = () => {
  return (
    <BrowserRouter>
      <Header />
      <div className={'wrapper position-relative'}>
        <Routes />
      </div>
    </BrowserRouter>
  );
};
