//проверяет на пустоту
export const isNotEmpty = (any) => {
  if (typeof any === 'string') return any.trim().length > 0;
  if (typeof any === 'object') return Object.keys(any).length > 0;
  if (Array.isArray(any)) return any.length > 0;
  return true;
};
//
export const debounce = (func, timeout = 500) => {
  let timer;
  return function (...args) {
    // eslint-disable-next-line no-invalid-this
    const context = this;
    if (timer) clearTimeout(timer);

    timer = setTimeout(() => {
      timer = null;
      func.apply(context, args);
    }, timeout);
  };
};

//функция возвращает текст ошибки валидации формы в зависимости от ее типа
export const displayTextError = (error) => {
  if (error.type === 'required') return 'Заполните поле';
  if (error.type === 'maxLength')
    return 'Превышено максимальное количество символов';
  if (error.type === 'pattern') return 'Неверный формат';
};

//возвращает регулярное выражение в зависимости от выбранного языка
export const changePatternLanguageInput = (language) => {
  switch (language) {
    case 'ru':
      return /^[а-яА-Я \d]+$/;
    case 'en':
      return /^[a-zA-Z \d]+$/;
  }
};

// тема для select
export const selectThemeColorsCustom = (theme) => ({
  ...theme,
  colors: {
    ...theme.colors,
    primary25: '#7367f01a', // for option hover bg-color
    primary: '#88BC87', // for selected option bg-color
    neutral10: '#88BC87', // for tags bg-color
    neutral20: '#ededed', // for input border-color
    neutral30: '#88BC87', // for input hover border-color
  },
});

//проверяем пересечение массивов для options select
export const checkDefaultMultiSelect = (optionsSelect, defaultValues) => {
  const selectedItemsSet = new Set(defaultValues);

  const filteredOptionsArr = optionsSelect.filter((item) =>
    selectedItemsSet.has(item.value)
  );

  return filteredOptionsArr;
};

// получаем options для select
export const getOptions = (data) => {
  const options = [];
  data.map((item) => {
    options.push({ value: item.id, label: item.name });
  });
  return options;
};

// проверяем query параметры на пустоту
export const checkQueryParams = (queryParams) => {
  const params = {};
  for (const key in queryParams) {
    if (isNotEmpty(queryParams[key])) params[key] = queryParams[key];
  }
  return params;
};
