// ** Router Import
// import Router from './router/Router'


import {Main} from './layouts/pages/Main/Main'
import './layouts/scss/index.scss'
import { LoginPage } from './layouts/pages/LoginPage/LoginPage' 
import { useContext } from 'react';
import { AuthContext } from './utility/context/AuthContext';
import { useState } from 'react';
import { useEffect } from 'react';


export const App = () => {
  const { isAuthState } = useContext(AuthContext);
  const [isLoggedIn, setIsLoggedIn] = useState(isAuthState);

  useEffect(() => {
    setIsLoggedIn(isAuthState)
  }, [isAuthState])

  return isLoggedIn ?  <Main/> : <LoginPage/>

}
