import { Button } from 'reactstrap';
import React, { useEffect, useState } from 'react';
import emptyIcon from '@src/assets/images/emptyCoverPhoto.jpg';
import { whiteTrash } from '../../../assets/images/svg';
import { ModalBasic } from '../modal/Modal';
import { ImageCrop } from '../imageCrop/ImageCrop';

export const CoverPhoto = ({ setPhoto, photo, deletePhotos }) => {
  const [isCropped, setIsCropped] = useState(false);
  const [isShowModal, setIsShowModal] = useState(false);
  const [photoState, setPhotoState] = useState(null);
  const src = photoState ? URL.createObjectURL(photoState) : photo;

  useEffect(() => {
    if (deletePhotos) {
      setPhoto(null);
      setPhotoState(null);
    }
  }, [deletePhotos]);

  const onApplyCrop = (cropperRef) => {
    const canvas = cropperRef.current?.getCanvas();
    if (canvas) {
      canvas.toBlob((blob) => {
        if (blob) {
          const myFile = new File([blob], `${Date.now()}.jpeg`, {
            type: blob.type,
          });
          setPhotoState(myFile);
          setPhoto(myFile);
        }
      }, 'image/jpeg');
      setIsCropped(true);
      setIsShowModal(false);
    }
  };

  return (
    <div className={'position-relative full-width height-250 rounded card'}>
      <img
        className={`full-width height-250 card-img ${
          src ? 'cursor-pointer' : ''
        }`}
        src={src || emptyIcon}
        alt={'фото'}
        onClick={() => {
          if (src) setIsShowModal(true);
        }}
      />
      <div
        className={
          'position-absolute d-flex justify-content-between p-2 full-width align-items-start'
        }
      >
        <div className={'d-flex flex-column'}>
          <label className={'btn round mb-1 new-photo'} color={'success'}>
            Загрузить новую обложку
            <input
              onChange={(e) => {
                setPhotoState(e.target.files[0]);
                setPhoto(e.target.files[0]);
                setIsCropped(true);
              }}
              type="file"
              accept="image/jpeg, image/png, image/gif"
              className={'visually-hidden'}
            />
          </label>
          <span style={{ color: '#ffffff' }}>
            JPG, Gif или PNG. Макс. размер файла 20mb
          </span>
        </div>

        <button
          className={'delete-photo'}
          onClick={() => {
            setPhoto(null);
            setPhotoState(null);
          }}
          type={'button'}
        >
          {whiteTrash}
        </button>
      </div>
      <ModalBasic
        setIsShowModal={setIsShowModal}
        isShowModal={isShowModal}
        body={
          <ImageCrop
            photo={photo}
            onCancel={() => {
              setIsShowModal(false);
            }}
            onApply={onApplyCrop}
            isCropped={isCropped}
            setIsCropped={setIsCropped}
            aspectRatio={2 / 1}
          />
        }
      />
    </div>
  );
};
