import { Img } from '../../components/img/Img';
import { Button, Input, Spinner } from 'reactstrap';
import React, { useEffect, useState } from 'react';
import { useForm, Controller } from 'react-hook-form';
import { Link, useHistory } from 'react-router-dom';
import { displayTextError, isNotEmpty } from '../../functions/helpers';
import { useSelector } from 'react-redux';
import { Loader } from '../../components/loader/Loader';
import { toast } from 'react-hot-toast';
import { ToasterCustom } from '../../components/toster/Toaster';
import {
  createPest,
  deletePest,
  getPestInfo,
  updatePest,
} from '../../../api/requests';
import { storage } from '../../../configs/firebase';
import { getDownloadURL, ref, uploadBytes } from 'firebase/storage';
import { ModalBasic } from '../../components/modal/Modal';
import {
  DeleteModalBody,
  DeleteModalFooter,
} from '../../components/modal/modalComponents/ModalComponents';
import { PestName } from './pestFields/PestName';
import { PestDescription } from './pestFields/PestDescription';

export const Pest = (props) => {
  const [pestData, setPestData] = useState(null);
  const [photo, setPhoto] = useState(null);
  const [isLoader, setIsLoader] = useState(false);
  const [isShowModal, setIsShowModal] = useState(false);
  const pestId = props.match.params.id;
  const history = useHistory();
  // const language = useSelector((state) => state.language);
  const [deletePhotos, setDeletePhotos] = useState(!pestId);
  const [isSavingPest, setIsSavingPest] = useState(false);

  const defaultValues = {
    name_ru: pestId ? pestData?.localizedName?.ru || pestData?.name : '',
    name_en: pestId ? pestData?.localizedName?.en || pestData?.name : '',
    description_ru: pestId
      ? pestData?.localizedDescription?.ru || pestData?.description
      : '',
    description_en: pestId
      ? pestData?.localizedDescription?.en || pestData?.description
      : '',
  };
  const {
    control,
    handleSubmit,
    reset,
    setValue,
    formState: { errors, isDirty },
  } = useForm({ defaultValues });

  useEffect(() => {
    setIsLoader(true);
    if (pestId) {
      const pestInfoPromise = new Promise(function (resolve) {
        const result = getPestInfo(pestId);
        resolve(result);
      });

      pestInfoPromise.then((value) => {
        setPestData(value);
        setPhoto(value.photo);
        setIsLoader(false);
      });
    } else {
      setPestData(null);
      setDeletePhotos(true);
      reset();
      setIsLoader(false);
    }
  }, [pestId]);

  useEffect(() => {
    reset(defaultValues);
  }, [pestData]);

  // useEffect(() => {
  //   if (!pestId) {
  //     setValue(`name_${language}`, '');
  //     setValue(`description_${language}`, '');
  //   }
  // }, [language, pestId]);

  const onSubmit = async (data) => {
    setDeletePhotos(false);
    setIsSavingPest(true);
    if (!photo) {
      toast.error('Проверьте, добавили ли вы изображение вредителя!', {
        autoClose: 15000,
      });
      setIsSavingPest(false);
      return;
    }

    const objData = {};

    if (pestId) {
      if (photo !== pestData.photo) {
        try {
          const pestsRef = ref(storage, `pests/${photo.name}`);
          await uploadBytes(pestsRef, photo).then((snapshot) =>
            getDownloadURL(snapshot.ref).then((url) => {
              objData.photo = url;
            })
          );
        } catch (e) {
          toast.error(e);
          setIsSavingPest(false);
        }
      }

      if (isDirty) {
        Object.keys(data).map((item) => {
          if (item.includes('description') || item.includes('name')) {
            const splitName = item.split('_');
            const hightFirstLetterName =
              splitName[0][0].toUpperCase() + splitName[0].slice(1);
            const lang = splitName[1];

            objData[`localized${hightFirstLetterName}.${lang}`] = data[item];

            if (lang === 'ru') {
              objData[splitName[0]] = data[item];
            }
          } else {
            objData[item] = data[item];
          }
        });
      }

      try {
        await updatePest(pestId, objData);
        toast.success('Изменения сохранены!');
      } catch (e) {
        toast.error('Не удалось внести изменения!');
        console.log(e);
      }
    } else {
      if (photo) {
        try {
          const pestsRef = ref(storage, `pests/${photo.name}`);
          await uploadBytes(pestsRef, photo).then((snapshot) =>
            getDownloadURL(snapshot.ref).then((url) => {
              objData.photo = url;
            })
          );
        } catch (e) {
          toast.error(e);
          setIsSavingPest(false);
        }
      }

      Object.keys(data).map((item) => {
        if (item.includes('description') || item.includes('name')) {
          const splitName = item.split('_');
          const hightFirstLetterName =
            splitName[0][0].toUpperCase() + splitName[0].slice(1);
          const lang = splitName[1];
          if (objData[`localized${hightFirstLetterName}`]) {
            objData[`localized${hightFirstLetterName}`][lang] = data[item];
          } else {
            objData[`localized${hightFirstLetterName}`] = {
              [lang]: data[item],
            };
          }
          objData[splitName[0]] =
            objData[`localized${hightFirstLetterName}`].ru;
        } else {
          objData[item] = data[item];
        }
      });

      // if (
      //   !objData?.localizedDescription?.en ||
      //   !objData?.localizedName?.en ||
      //   !objData?.localizedDescription?.ru ||
      //   !objData?.localizedName?.ru
      // ) {
      //   toast.error('Введите значения на всех языках!', { autoClose: 15000 });
      //   setIsSavingPest(false);
      //   return;
      // }

      try {
        await createPest(objData);
        reset();
        setDeletePhotos(true);
        toast.success('Вредитель добавлен!');
      } catch (e) {
        toast.error('Ошибка добавления!');
        console.log(e);
      }
    }

    setIsSavingPest(false);
  };

  const onDeletePest = async () => {
    try {
      await deletePest(pestId);
      setIsShowModal(false);
      history.push('/pests');
    } catch (e) {
      console.log(e);
    }
  };

  if (isLoader) return <Loader />;

  return (
    <>
      <form className={'card p-2'} onSubmit={handleSubmit(onSubmit)}>
        <ToasterCustom />
        <div
          className={
            'd-flex justify-content-between align-items-center border-bottom-secondary pb-2'
          }
        >
          <Img photo={photo} setPhoto={setPhoto} deletePhotos={deletePhotos} setDeletePhotos={setDeletePhotos}/>
          {pestId && (
            <Button
              className={'delete-button'}
              color={'danger'}
              onClick={() => {
                setIsShowModal(true);
              }}
              outline
            >
              {'Удалить'}
            </Button>
          )}
        </div>
        <h2 className={'my-2 card-subtitle'}>ОБЩАЯ ИНФОРМАЦИЯ</h2>

        <PestName control={control} errors={errors} />

        <PestDescription
          control={control}
          errors={errors}
          pestData={pestData}
        />
        <div className="mt-3">
          <Button
            color={'success'}
            className={'br-10 me-2'}
            disabled={isSavingPest}
          >
            {isSavingPest ? <Spinner size={'sm'} /> : 'Сохранить'}
          </Button>
          <Link to={'/pests'} className={'br-10 btn cancel'}>
            {'Отмена'}
          </Link>
        </div>
      </form>

      <ModalBasic
        setIsShowModal={setIsShowModal}
        isShowModal={isShowModal}
        body={<DeleteModalBody plantName={pestData?.name} type={'вредителя'} />}
        footer={
          <DeleteModalFooter
            onCancel={() => {
              setIsShowModal(false);
            }}
            onDelete={onDeletePest}
          />
        }
      />
    </>
  );
};
