import iconInfo from '@src/assets/images/IconInformation.png'
import {NavLink} from 'react-router-dom'
import defaultPest from '@src/assets/images/defaultPest.png'
import { getDefaultSrc } from '../../../../components/img/Img'

// const checkItem = (item) => {
//   if (item.plant) return item.plant
//   if (item.pest) return item.pest
//   return item
// }
const checkStatus = (item) => {
  if (item.status === 'new') return 'Новое'
  if (item.status === 'imported') return 'Импортировано'
  if (item.status === 'viewed') return 'Просмотрено'
}

const namesForPages = (item, language) => ({
  '/plants':  item?.data?.localizedVariety?.[language] || item?.data?.variety,
  '/pests': item?.data?.localizedName?.[language] || item?.data?.name,
  '/benefits': item?.data?.localizedName?.[language] || item?.data?.name,
})

export const RowTable = ({item, path, language}) => {

  return (
    <tr className={item.status === 'new' ? 'bg-success' : ''}>
      <td width={'300'}>
        {item.id}
      </td>
      {!path.includes('benefits') && <td width={'150'}>
        <img src={item.data.photo || getDefaultSrc(path)} alt="фото"
             className={'rounded-circle bg-white'}
             style={{height: '46px', width: '46px'}}/>
      </td>}
      <td>
        {namesForPages(item, language)[path]}
      </td>
      <td>
        {path.includes('/users') ? (checkStatus(item)) : ''}
      </td>
      <td className={'infoFull'}  width={'250'}>
        <NavLink to={`${path}/${item.id}`} className={'btn bg-custom-full-info'}>
          <div className={''}>
            <img src={iconInfo} alt="!" className={'me-1'}/>
            <span>Полная информация</span>
          </div>
        </NavLink>
      </td>

    </tr>

  )
}

