import AsyncSelect from 'react-select/async';
import React from 'react';
import { Controller } from 'react-hook-form';
import { isNotEmpty, selectThemeColorsCustom} from '../../../functions/helpers';


export const PestsSelect = ({ control, errors, optionsSelect }) => {
  return (
    <div className='mb-3'>
      <div className={'d-flex justify-content-between'}>
        <h2 className={'mb-1 text-white'}>БОЛЕЗНИ И ВРЕДИТЕЛИ</h2>
        <p
          className={'align-self-end m-0 text-danger'}
          style={{ height: '1.5rem' }}
        >
          {errors.pests?.message || ''}
        </p>
      </div>
      <Controller
        name={'pests'}
        control={control}
        render={({ field }) => (
          <AsyncSelect
            {...field}
            theme={selectThemeColorsCustom}
            cacheOptions
            className={`basic-multi-select ${
              isNotEmpty(errors)
                ? errors.pests
                  ? 'border-danger'
                  : 'border-success'
                : 'border-1'
            }`}
            isMulti
            placeholder={'Выберите вредителя'}
            classNamePrefix={'select'}
            isClearable={false}
            maxMenuHeight={250}
            loadingMessage={() => 'Идет загрузка...'}
            noOptionsMessage={() => 'Ничего не найдено'}
            // onMenuScrollToBottom={() => {
            //   if (isNextPage) setPage((prevState) => prevState + 1)
            // }}
            defaultOptions={optionsSelect}
            // loadOptions={promiseOptions}
          />
        )}
      />
    </div>
  );
};
