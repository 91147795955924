export const info = <svg
  width="20" height="20" viewBox="0 0 20 20" fill="none"
  xmlns="http://www.w3.org/2000/svg">
  <path fillRule="evenodd" clipRule="evenodd"
        d="M10.0003 18.3332C14.6027 18.3332 18.3337 14.6022 18.3337 9.99984C18.3337 5.39746 14.6027 1.6665 10.0003 1.6665C5.39795 1.6665 1.66699 5.39746 1.66699 9.99984C1.66699 14.6022 5.39795 18.3332 10.0003 18.3332Z"
        stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
  <path
    d="M10.3311 15H9.06641V7.60352H10.3311V15ZM8.96387 5.6416C8.96387 5.43652 9.02539 5.26335 9.14844 5.12207C9.27604 4.98079 9.46289 4.91016 9.70898 4.91016C9.95508 4.91016 10.1419 4.98079 10.2695 5.12207C10.3971 5.26335 10.4609 5.43652 10.4609 5.6416C10.4609 5.84668 10.3971 6.01758 10.2695 6.1543C10.1419 6.29102 9.95508 6.35938 9.70898 6.35938C9.46289 6.35938 9.27604 6.29102 9.14844 6.1543C9.02539 6.01758 8.96387 5.84668 8.96387 5.6416Z"
    fill="currentColor"/>
</svg>

export const arrowLogin = <svg width="26" height="27" viewBox="0 0 26 27" fill="none"
                        xmlns="http://www.w3.org/2000/svg">
  <g>
    <path d="M5 12.2815H21" stroke="white" strokeLinecap="round" strokeLinejoin="round"/>
    <path d="M15 6.2159L21 12.2815L15 18.347" stroke="white" strokeLinecap="round"
          strokeLinejoin="round"/>
  </g>
  <defs>
    <filter id="filter0_d_560_57470" x="-3" y="0.15033" width="32" height="32.2623"
            filterUnits="userSpaceOnUse" colorInterpolationFilters="sRGB">
      <feFlood floodOpacity="0" result="BackgroundImageFix"/>
      <feColorMatrix in="SourceAlpha" type="matrix"
                     values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha"/>
      <feOffset dy="4"/>
      <feGaussianBlur stdDeviation="2"/>
      <feComposite in2="hardAlpha" operator="out"/>
      <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.25 0"/>
      <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_560_57470"/>
      <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow_560_57470" result="shape"/>
    </filter>
  </defs>
</svg>

export const whiteTrash = <svg width="20" height="21" viewBox="0 0 20 21" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M4.375 4.875L5.15625 17.375C5.19336 18.0973 5.71875 18.625 6.40625 18.625H13.5938C14.284 18.625 14.7996 18.0973 14.8438 17.375L15.625 4.875" stroke="white" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
<path d="M3.125 4.875H16.875" stroke="white" strokeWidth="1.5" strokeMiterlimit="10" strokeLinecap="round"/>
<path d="M7.5 4.875V3.3125C7.49964 3.18929 7.52365 3.06722 7.57063 2.95331C7.61762 2.83941 7.68666 2.73591 7.77379 2.64879C7.86091 2.56166 7.96441 2.49262 8.07831 2.44563C8.19222 2.39865 8.31429 2.37464 8.4375 2.375H11.5625C11.6857 2.37464 11.8078 2.39865 11.9217 2.44563C12.0356 2.49262 12.1391 2.56166 12.2262 2.64879C12.3133 2.73591 12.3824 2.83941 12.4294 2.95331C12.4764 3.06722 12.5004 3.18929 12.5 3.3125V4.875" stroke="white" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
</svg>
