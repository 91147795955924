import React, { useEffect, useRef, useState } from 'react';
import { Cropper, CropperPreview } from 'react-advanced-cropper';
import 'react-advanced-cropper/dist/style.css';
import './ImageCrop.scss';
import { Button } from 'reactstrap';

export const ImageCrop = ({
  photo,
  onCancel,
  onApply,
  isCropped,
  aspectRatio,
  isRound
}) => {
  const [src, setSrc] = useState(photo);
  const cropperRef = useRef(null);
  const previewRef = useRef(null);
  const previewRef2 = useRef(null);

  const onUpdate = () => {
    previewRef.current?.refresh();
    previewRef2.current?.refresh();
  };

  useEffect(() => {
    isCropped && photo && setSrc(URL.createObjectURL(photo));
  }, [photo]);

  return (
    <>
      <Cropper
        ref={cropperRef}
        src={src}
        onUpdate={onUpdate}
        className={'cropper cropper-custom'}
        stencilProps={{
          aspectRatio,
        }}
      />
      <div className={'crop-preview my-2 d-flex gap-2'}>
        <CropperPreview
          ref={previewRef}
          cropper={cropperRef}
          className={`preview crop-preview-item crop-preview-item_big ${isRound ? 'isRound' : ''}`}
        />
        {isRound && <CropperPreview
          ref={previewRef2}
          cropper={cropperRef}
          className="preview crop-preview-item crop-preview-item_small"
        />}
      </div>
      <div className="d-flex gap-5 justify-content-center">
        <Button type={'button'} outline className="br-10" onClick={onCancel}>
          Отмена
        </Button>
        <Button
          type={'button'}
          color={'success'}
          className="br-10 mr-3"
          onClick={() => {
            onApply(cropperRef);
          }}
        >
          Применить
        </Button>
      </div>
    </>
  );
};
