import { Input } from 'reactstrap';
import { displayTextError, isNotEmpty } from '../../../functions/helpers';
import { Controller } from 'react-hook-form';

export const BenefitName = ({ control, errors }) => {
  return (
    <>
      <div className={'d-flex justify-content-between'}>
        <p className={'text-white text-uppercase m-0'}>Название</p>
        {/* <p
          className={'align-self-end m-0 text-danger'}
          style={{ height: '1.5rem' }}
        >
          {errors?.[`name_${language}`]
            ? displayTextError(errors[`name_${language}`])
            : ''}
        </p> */}
      </div>
      <div className={'d-flex'}>
        <div className={'w-50 me-1'}>
          <div className={'d-flex justify-content-between'}>
            <p className={'text-white text-uppercase m-0'}>RU</p>
            <p
              className={'align-self-end m-0 text-danger'}
              style={{ height: '1.5rem' }}
            >
              {errors?.[`name_ru`] ? displayTextError(errors[`name_ru`]) : ''}
            </p>
          </div>
          <Controller
            // key={`${language}_1`}
            // name={`name_${language}`}
            name={'name_ru'}
            control={control}
            rules={{
              required: true,
              maxLength: 500,
              validate: {
                notEmpty: (v) => String(v).trim().length > 0,
              },
            }}
            render={({ field }) => (
              <Input
                {...field}
                placeholder={'Введите название'}
                className={`card-input ${
                  isNotEmpty(errors)
                    ? errors[`name_ru`]
                      ? 'border-danger'
                      : 'border-success'
                    : 'border-1'
                } round mb-2`}
              />
            )}
          />
        </div>
        <div className={'w-50'}>
          <div className={'d-flex justify-content-between'}>
            <p className={'text-white text-uppercase m-0'}>EN</p>
            <p
              className={'align-self-end m-0 text-danger'}
              style={{ height: '1.5rem' }}
            >
              {errors?.[`name_en`] ? displayTextError(errors[`name_en`]) : ''}
            </p>
          </div>
          <Controller
            // key={`${language}_1`}
            // name={`name_${language}`}
            name={'name_en'}
            control={control}
            rules={{
              required: true,
              maxLength: 500,
              validate: {
                notEmpty: (v) => String(v).trim().length > 0,
              },
            }}
            render={({ field }) => (
              <Input
                {...field}
                placeholder={'Введите название'}
                className={`card-input ${
                  isNotEmpty(errors)
                    ? errors[`name_en`]
                      ? 'border-danger'
                      : 'border-success'
                    : 'border-1'
                } round mb-2`}
              />
            )}
          />
        </div>
      </div>
    </>
  );
};
