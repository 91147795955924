import { Nav } from './nav/Nav';
import { NavRight } from './navRight/navRight';
import './header.scss';

export const Header = () => {
  return (
    <header className="position-relative zindex-1">
      <div className="wrapper">
        <Nav />
        <NavRight />
      </div>
    </header>
  );
};
