import { Controller } from 'react-hook-form';
import { useSelector } from 'react-redux';
import { displayTextError, isNotEmpty } from '../../../functions/helpers';
import { Input } from 'reactstrap';

export const PestName = ({ control, errors }) => {
  return (
    <>
      <div className={'d-flex justify-content-between mb-1'}>
        <p className={'text-white m-0'}>НАЗВАНИЕ ВРЕДИТЕЛЯ</p>
        {/* <p
            className={'align-self-end m-0 text-danger'}
            style={{ height: '1.5rem' }}
          >
            {errors?.[`name_${language}`]
              ? displayTextError(errors[`name_${language}`])
              : ''}
          </p> */}
      </div>
      <div className="d-flex mb-2">
        <div className="w-50 me-1">
          <div className={'d-flex justify-content-between'}>
            <p className={'text-white m-0'}>RU</p>
            <p
              className={'align-self-end m-0 text-danger'}
              style={{ height: '1.5rem' }}
            >
              {errors?.[`name_ru`] ? displayTextError(errors[`name_ru`]) : ''}
            </p>
          </div>
          <Controller
            // key={`${language}_1`}
            // name={`name_${language}`}
            name={'name_ru'}
            control={control}
            rules={{
              required: true,
              maxLength: 50,
              validate: {
                notEmpty: (v) => String(v).trim().length > 0,
              },
              pattern: /[^\!@#$%^&*~]$/,
            }}
            render={({ field }) => (
              <Input
                {...field}
                placeholder={'Введите название вредителя (болезни)'}
                className={`card-input ${
                  isNotEmpty(errors)
                    ? errors[`name_en`]
                      ? 'border-danger'
                      : 'border-success'
                    : 'border-1'
                } round`}
              />
            )}
          />
        </div>
        <div className="w-50">
          <div className={'d-flex justify-content-between'}>
            <p className={'text-white m-0'}>EN</p>
            <p
              className={'align-self-end m-0 text-danger'}
              style={{ height: '1.5rem' }}
            >
              {errors?.[`name_en`] ? displayTextError(errors[`name_en`]) : ''}
            </p>
          </div>
          <Controller
            // key={`${language}_1`}
            // name={`name_${language}`}
            name={'name_en'}
            control={control}
            rules={{
              required: true,
              maxLength: 50,
              validate: {
                notEmpty: (v) => String(v).trim().length > 0,
              },
              pattern: /[^\!@#$%^&*~]$/,
            }}
            render={({ field }) => (
              <Input
                {...field}
                placeholder={'Введите название вредителя (болезни)'}
                className={`card-input ${
                  isNotEmpty(errors)
                    ? errors[`name_en`]
                      ? 'border-danger'
                      : 'border-success'
                    : 'border-1'
                } round`}
              />
            )}
          />
        </div>
      </div>
    </>
  );
};
