import { Controller } from "react-hook-form";
import AsyncSelect from 'react-select/async';
import { isNotEmpty, selectThemeColorsCustom } from "../../../functions/helpers";

export const BenefitsSelect = ({ control, errors, optionsSelect }) => {
  return (
    <div>
      <div className={'d-flex justify-content-between'}>
        <h2 className={'mb-1 text-white text-uppercase'}>Польза</h2>
        <p
          className={'align-self-end m-0 text-danger'}
          style={{ height: '1.5rem' }}
        >
          {errors.pests?.message || ''}
        </p>
      </div>
      <Controller
        name={'benefits'}
        control={control}
        render={({ field }) => (
          <AsyncSelect
            {...field}
            theme={selectThemeColorsCustom}
            cacheOptions
            className={`basic-multi-select ${
              isNotEmpty(errors)
                ? errors.pests
                  ? 'border-danger'
                  : 'border-success'
                : 'border-1'
            }`}
            isMulti
            placeholder={'Выберите пользу'}
            classNamePrefix={'select'}
            isClearable={false}
            maxMenuHeight={250}
            loadingMessage={() => 'Идет загрузка...'}
            noOptionsMessage={() => 'Ничего не найдено'}
            // onMenuScrollToBottom={() => {
            //   if (isNextPage) setPage((prevState) => prevState + 1)
            // }}
            defaultOptions={optionsSelect}
            // loadOptions={promiseOptions}
          />
        )}
      />
    </div>
  );
}