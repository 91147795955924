// ** React Imports
// import { Suspense, lazy } from 'react'
import './layouts/scss/reset.scss';
import React from 'react';
import ReactDOM from 'react-dom';
import { App } from './App';
// import 'sweetalert2/src/sweetalert2.scss'
// import 'animate.css'
//
// // ** Redux Imports
// import { Provider } from 'react-redux'
// import { store } from './redux/store'
//
// // ** Intl & ThemeColors Context
// import { ToastContainer } from 'react-toastify'
// import { ThemeContext } from './utility/context/ThemeColors'
//
// // ** Spinner (Splash Screen)
// import Spinner from './@core/components/spinner/Fallback-spinner'
//
// // ** Ripple Button
// import './@core/components/ripple-button'
//
// // ** PrismJS
import 'prismjs';
import 'prismjs/themes/prism-tomorrow.css';
import 'prismjs/components/prism-jsx.min';
//
// // ** React Perfect Scrollbar
// import 'react-perfect-scrollbar/dist/css/styles.css'
//
// // ** React Toastify
import '@styles/react/libs/toastify/toastify.scss';
//
// // ** Core styles
import './@core/assets/fonts/feather/iconfont.css';
import './@core/scss/core.scss';
import './assets/scss/style.scss';
import { Provider } from 'react-redux';
import { store } from './layouts/store/store';
import { ToastContainer } from 'react-toastify';
import { AuthProvider } from './utility/context/AuthContext';

// // ** Service Worker
// import * as serviceWorker from './serviceWorker'
//
// // ** Lazy load app
// const LazyApp = lazy(() => import('./App'))

ReactDOM.render(
  <React.StrictMode>
    <AuthProvider>
      <Provider store={store}>
        <ToastContainer
          position="bottom-right"
          autoClose={5000}
          hideProgressBar={false}
          newestOnTop={false}
          closeOnClick
          rtl={false}
          pauseOnFocusLoss
          draggable
          pauseOnHover
        />
        <App />
      </Provider>
    </AuthProvider>
  </React.StrictMode>,
  document.getElementById('root')
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
// serviceWorker.unregister()
