
export const fieldsFormPlantLeft = [
  {
    name: 'careComplexity',
    label: 'сложность ухода',
    type: 'select'
  },
  {
    name: 'wateringSummer',
    label: 'полив (летом), дней',
    type: 'number'
  },
  {
    name: 'wateringWinter',
    label: 'полив (зимой), дней',
    type: 'number'
  },
  {
    name: 'sprayingSummer',
    label: 'опрыскивание (летом), дней',
    type: 'number'
  },
  {
    name: 'sprayingWinter',
    label: 'опрыскивание (зимой), дней',
    type: 'number'
  },
  {
    name: 'feedingSummer',
    label: 'подкормка (летом), дней',
    type: 'number'
  },
  {
    name: 'feedingWinter',
    label: 'подкормка (зимой), дней',
    type: 'number'
  }

]

export const fieldsFormPlantRight = [
  {
    localized: 'localizedPruning',
    name: 'pruning',
    label: 'обрезка',
    type: 'text'
  },
  [
    {
      name: 'minTempSummer', 
      label: 'температура (летом) от ℃',
      type: 'number'
    },
    {
      name: 'maxTempSummer',
      label: 'температура (летом) до ℃',
      type: 'number'
    },
    {
      name: 'minTempWinter',
      label: 'температура (зимой) от ℃',
      type: 'number'
    },
    {
      name: 'maxTempWinter',
      label: 'температура (зимой) до ℃',
      type: 'number'
    }
  ],

  {
    localized: 'localizedPlantingTime',
    name: 'plantingTime',
    label: 'время посадки',
    type: 'text'
  },
  {
    name: 'reproduction',
    label: 'размножение',
    type: 'selectMulti'
  },
  {
    name: 'sunRelation',
    label: 'ОсвЕЩЕНИЕ',
    type: 'select'
  }
]

export const optionsCareComplexity = [
  {
    value: 'EASY',
    label: 'Очень легко'
  },
  {
    value: 'MEDIUM',
    label: 'Легко'
  },
  {
    value: 'DIFFICULT',
    label: 'Непросто'
  },
  {
    value: 'VERY_DIFFICULT',
    label: 'Сложно'
  }
]

export const optionsReproduction = [
  {
    value: 'SEEDS',
    label: 'Семенами'
  },
  {
    value: 'CUTTINGS',
    label: 'Черенками'
  },
  {
    value: 'LEAVES',
    label: 'Листьями'
  },
  {
    value: 'AIR_LAYERS',
    label: 'Воздушными отводками'
  },
  {
    value: 'SEPARATING_BY_THE_BUSH',
    label: 'Делением куста'
  },
  {
    value: 'SPORES',
    label: 'Спорами'
  },
  {
    value: 'SEPARATING_BY_THE_ROOTCTOCK',
    label: 'Делением корневища'
  },
  {
    value: 'DAUGHTER_PLANTS',
    label: 'Дочерними растениями'
  }
]

export const optionsSunRelation = [
  {
    value: 'DIFFUSED_LIGHT',
    label: 'Яркий, рассеянный свет'
  },
  {
    value: 'DIRECT_LIGHT',
    label: 'Прямые солнечные лучи'
  },
  {
    value: 'PENUMBRA',
    label: 'Полутень'
  }
]