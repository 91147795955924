import { Button, Modal, ModalBody, ModalFooter, ModalHeader } from 'reactstrap';

export const ModalBasic = ({ setIsShowModal, isShowModal, title, body, footer }) => {
  return (
    <div className="vertically-centered-modal">
      <Modal
        isOpen={isShowModal}
        toggle={() => setIsShowModal(!isShowModal)}
        className="modal-dialog-centered"
      >
        <ModalHeader toggle={() => setIsShowModal(!isShowModal)} className='bg-transparent'>
          {title}
        </ModalHeader>
        <ModalBody>{body}</ModalBody>
        <ModalFooter className={'justify-content-center'}>
          {footer}
        </ModalFooter>
      </Modal>
    </div>
  );
};
