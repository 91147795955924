import {createSlice} from '@reduxjs/toolkit'

const sliceConfig = {
    name: 'reduxGG',
    initialState: {
        language: 'ru',
        auth: false
    },
    reducers: {
        setLanguage: (state, action) => {
            state.language = action.payload
        },
        setAuth: (state, action) => {
            state.auth = action.payload
        }
    }
}
export const {reducer, actions} = createSlice(sliceConfig)