import { useEffect } from 'react';
import { displayTextError, isNotEmpty } from '../../../functions/helpers';
import { Controller } from 'react-hook-form';
import { Input } from 'reactstrap';
import { useState } from 'react';
import { descriptionMaxLength } from '../../plant/Plant';

export const PestDescription = ({ control, errors, pestData }) => {
  const [descriptionLengthEN, setDescriptionLengthEN] = useState(0);
  const [descriptionLengthRU, setDescriptionLengthRU] = useState(0);
  useEffect(() => {
    setDescriptionLengthEN(
      pestData?.localizedDescription?.en?.length ||
        pestData?.description?.length ||
        0
    );
    setDescriptionLengthRU(
      pestData?.localizedDescription?.ru?.length ||
        pestData?.description?.length ||
        0
    );
  }, [pestData]);

  return (
    <div className="mb-2">
      <div className={'d-flex justify-content-between mt-2'}>
        <h3 className={'card-subtitle'}>ОПИСАНИЕ</h3>
        {/* <p
        className={'m-0 align-self-end text-danger'}
        style={{ height: '1.5rem' }}
      >
        {errors?.[`description_${language}`]
          ? displayTextError(errors[`description_${language}`])
          : ''}
      </p> */}
      </div>

      <div className="d-flex">
        <div className="w-50 me-1">
          <div className={'d-flex justify-content-between mt-2'}>
            <p className={'text-white m-0'}>RU</p>
            <p
              className={'m-0 align-self-end text-danger'}
              style={{ height: '1.5rem' }}
            >
              {errors?.[`description_ru`]
                ? displayTextError(errors[`description_ru`])
                : ''}
            </p>
          </div>
          <Controller
            // key={language}
            name={`description_ru`}
            control={control}
            rules={{
              required: true,
              maxLength: descriptionMaxLength,
              validate: {
                notEmpty: (v) => String(v).trim().length > 0,
              },
            }}
            render={({ field: { onChange, ...field } }) => (
              <Input
                {...field}
                className={`card-input ${
                  isNotEmpty(errors)
                    ? errors[`description_ru`]
                      ? 'border-danger'
                      : 'border-success'
                    : 'border-1'
                } height-250`}
                onChange={(e) => {
                  onChange(e);
                  setDescriptionLengthRU(e.target.value.length);
                }}
                placeholder={'Общая информация о вредителе (болезни)...'}
                type={'textarea'}
              />
            )}
          />
          <div
            className={`${
              descriptionLengthRU > descriptionMaxLength
                ? 'text-danger'
                : 'text-white'
            } mt-50`}
            style={{ textAlign: 'right' }}
          >
            {descriptionLengthRU}/{descriptionMaxLength}
          </div>
        </div>
        <div className="w-50">
          <div className={'d-flex justify-content-between mt-2'}>
            <p className={'text-white m-0'}>EN</p>
            <p
              className={'m-0 align-self-end text-danger'}
              style={{ height: '1.5rem' }}
            >
              {errors?.[`description_en`]
                ? displayTextError(errors[`description_en`])
                : ''}
            </p>
          </div>
          <Controller
            // key={language}
            name={`description_en`}
            control={control}
            rules={{
              required: true,
              maxLength: descriptionMaxLength,
              validate: {
                notEmpty: (v) => String(v).trim().length > 0,
              },
            }}
            render={({ field: { onChange, ...field } }) => (
              <Input
                {...field}
                className={`card-input ${
                  isNotEmpty(errors)
                    ? errors[`description_en`]
                      ? 'border-danger'
                      : 'border-success'
                    : 'border-1'
                } height-250`}
                onChange={(e) => {
                  onChange(e);
                  setDescriptionLengthEN(e.target.value.length);
                }}
                placeholder={'Общая информация о вредителе (болезни)...'}
                type={'textarea'}
              />
            )}
          />
          <div
            className={`${
              descriptionLengthEN > descriptionMaxLength
                ? 'text-danger'
                : 'text-white'
            } mt-50`}
            style={{ textAlign: 'right' }}
          >
            {descriptionLengthEN}/{descriptionMaxLength}
          </div>
        </div>
      </div>
    </div>
  );
};
