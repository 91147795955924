import logo from '@src/assets/images/logo.svg';
import './auth.scss';
import { useContext } from 'react';
import { Button } from 'reactstrap';
import { arrowLogin } from '@src/assets/images/svg';
import { signInWithEmailAndPassword } from 'firebase/auth';
import { useForm } from 'react-hook-form';
import { withCheckNet } from '../../../utility/hooks/firebase/helpers';
import { auth } from '../../../configs/firebase';
import { AuthContext } from '../../../utility/context/AuthContext';
import { useState } from 'react';

export const LoginPage = () => {
  const { register, setError, handleSubmit } = useForm({ mode: 'onChange ' });
  const { logInWtoken } = useContext(AuthContext);
  const [showUsernameError, setShowUsernameError] = useState(false);
  const [showPasswordError, setShowPasswordError] = useState(false);

  const onSubmit = ({ username, password }) => {
    withCheckNet(
      signInWithEmailAndPassword(auth, username, password)
        .then((userCredential) => {
          const user = userCredential.user;
          logInWtoken(user.uid);
        })
        .catch((error) => {
          setShowUsernameError(true);
          setShowPasswordError(true);
          console.log(error);
          // onErrorMessage(error, () => setFocus('username'))
        })
    );
  };

  return (
    <main className="auth">
      <div className="auth-form">
        <form onSubmit={handleSubmit(onSubmit)}>
          <img src={logo} alt="Garden Guru" />
          <h2>Добро пожаловать 👋🏻</h2>
          <p>Пожалуйста, войдите в свой аккаунт</p>
          <fieldset>
            <label>
              Логин
              <input
                type="text"
                placeholder="Введите ваш логин"
                {...register('username', {
                  required: true,
                })}
                className={showUsernameError ? 'border-danger' : ''}
                onChange={() => {
                  setShowUsernameError(false);
                }}
              />
            </label>
            <label className="password">
              Пароль
              <input
                type="password"
                placeholder="Введите ваш пароль"
                {...register('password', {
                  required: true,
                })}
                className={showPasswordError ? 'border-danger' : ''}
                onChange={() => {
                  setShowPasswordError(false);
                }}
              />
            </label>
            <Button color={'success'} className={'text-center full-width'}>
              Войти
            </Button>
            {(showUsernameError || showPasswordError) && (
              <p>
                Неверно введен Логин или Пароль.
                <br />
                Попробуйте еще раз!
              </p>
            )}
          </fieldset>
        </form>
      </div>

      <div className="auth-img"></div>
    </main>
  );
};
