import {Switch, Route, Redirect} from 'react-router-dom'
import {Plant} from '../layouts/pages/plant/Plant'
import {Pest} from '../layouts/pages/pest/Pest'
import { InfoGeneral } from '../layouts/pages/InfoGeneral/InfoGeneral'
import { Benefit } from '../layouts/pages/Benefit/Benefit'

export const Routes = () => {

  return (
    <Switch>
      <Route exact path={'/'}>
        <Redirect to={'/plants'}/>
      </Route>

      <Route exact path={'/plants'} component={InfoGeneral}/>
      <Route exact path={'/plants/newPlant'} component={Plant}/>

      <Route path={'/plants/:id'} component={Plant}/>

      <Route exact path={'/pests'} component={InfoGeneral}/>
      <Route exact path={'/pests/newPest'} component={Pest}/>

      <Route path={'/pests/:id'} component={Pest}/>

      <Route exact path={'/benefits'} component={InfoGeneral}/>
      <Route exact path={'/benefits/newBenefit'} component={Benefit}/>

      <Route path={'/benefits/:id'} component={Benefit}/>

      <Route exact path={'/users'}>
        <Redirect to={'/users/plants'}/>
      </Route>

      <Route exact path={'/users/pests'} component={InfoGeneral}/>
      <Route exact path={'/users/plants'} component={InfoGeneral}/>

      <Route path={'/users/pests/:id'} component={Pest}/>
      <Route path={'/users/plants/:id'} component={Plant}/>

      <Route component={() => <div>404</div>}/>

    </Switch>
  )
}