import {
  fieldsFormPlantRight,
  optionsReproduction,
  optionsSunRelation,
} from '../fieldsFormPlant';
import { Input } from 'reactstrap';
import Select from 'react-select';
import React from 'react';
import { Controller } from 'react-hook-form';
// import { useSelector } from 'react-redux';
import {
  displayTextError,
  isNotEmpty,
  selectThemeColorsCustom,
} from '../../../functions/helpers';

export const FormFieldsRight = ({ control, errors }) => {
  // const language = useSelector((state) => state.language);

  return (
    <>
      {fieldsFormPlantRight.map((item, index) => {
        if (Array.isArray(item))
          return (
            <div
              className={'d-flex flex-wrap justify-content-between'}
              key={index}
            >
              {item.map((_item, _index) => {
                return (
                  <div key={_index} className={'width-45-per'}>
                    <p className={`m-0 text-white text-uppercase`}>
                      {_item.label}
                    </p>

                    <Controller
                      control={control}
                      name={_item.name}
                      rules={{ required: true, pattern: /^\d+$/ }}
                      render={({ field }) => (
                        <Input
                          {...field}
                          placeholder={'Введите число'}
                          className={`card-input mb-0 ${
                            isNotEmpty(errors)
                              ? errors[_item.name]
                                ? 'border-danger'
                                : 'border-success'
                              : 'border-1'
                          }`}
                        />
                      )}
                    />
                    <div className={'d-flex justify-content-center'}>
                      <p
                        className={'m-0 align-self-end text-danger'}
                        style={{ height: '1.5rem' }}
                      >
                        {errors?.[_item.name]
                          ? displayTextError(errors[_item.name])
                          : ''}
                      </p>
                    </div>
                  </div>
                );
              })}
            </div>
          );
        if (item.type.includes('select'))
          return (
            <div key={index} className={'w-100'}>
              <div className={'d-flex justify-content-between'}>
                <p className={`m-0 text-white text-uppercase`}>{item.label}</p>
                <p
                  className={'m-0 align-self-end text-danger'}
                  style={{ height: '1.5rem' }}
                >
                  {errors[item.name]?.message || ''}
                </p>
              </div>

              <Controller
                name={item.name}
                control={control}
                rules={{ required: true }}
                render={({ field }) => (
                  <Select
                    {...field}
                    placeholder={'Выберите'}
                    maxMenuHeight={500}
                    isMulti={item.type === 'selectMulti'}
                    theme={selectThemeColorsCustom}
                    className={`basic-multi-select mb-2 ${
                      isNotEmpty(errors)
                        ? errors[item.name]
                          ? 'border-danger'
                          : 'border-success'
                        : 'border-1'
                    }`}
                    classNamePrefix={'select'}
                    isSearchable={false}
                    isClearable={false}
                    options={
                      item.name === 'reproduction'
                        ? optionsReproduction
                        : optionsSunRelation
                    }
                  />
                )}
              />
            </div>
          );
        if (item.localized) {
          return (
            <div key={index} className='d-flex'>
              <div className={'w-50 me-5'}>
                <div className={'d-flex justify-content-between'}>
                  <p className={`m-0 text-white text-uppercase`}>
                    {item.label} ( RU )
                  </p>
                  <p
                    className={'m-0 align-self-end text-danger'}
                    style={{ height: '1.5rem' }}
                  >
                    {errors?.[`${item.name}_ru`]
                      ? displayTextError(errors[`${item.name}_ru`])
                      : ''}
                  </p>
                </div>

                <Controller
                  // key={language}
                  name={`${item.name}_ru`}
                  control={control}
                  rules={{
                    required: true,
                    maxLength: 100,
                    validate: {
                      notEmpty: (v) => String(v).trim().length > 0,
                    },
                  }}
                  render={({ field }) => (
                    <Input
                      {...field}
                      placeholder={
                        item.type === 'number'
                          ? 'Введите число'
                          : 'Введите текст'
                      }
                      className={`card-input mb-2 ${
                        isNotEmpty(errors)
                          ? errors[`${item.name}_ru`]
                            ? 'border-danger'
                            : 'border-success'
                          : 'border-1'
                      }`}
                    />
                  )}
                />
              </div>
              <div className={'w-50'}>
                <div className={'d-flex justify-content-between'}>
                  <p className={`m-0 text-white text-uppercase`}>
                    {item.label} ( EN )
                  </p>
                  <p
                    className={'m-0 align-self-end text-danger'}
                    style={{ height: '1.5rem' }}
                  >
                    {errors?.[`${item.name}_en`]
                      ? displayTextError(errors[`${item.name}_en`])
                      : ''}
                  </p>
                </div>

                <Controller
                  // key={language}
                  name={`${item.name}_en`}
                  control={control}
                  rules={{
                    required: true,
                    maxLength: 100,
                    validate: {
                      notEmpty: (v) => String(v).trim().length > 0,
                    },
                  }}
                  render={({ field }) => (
                    <Input
                      {...field}
                      placeholder={
                        item.type === 'number'
                          ? 'Введите число'
                          : 'Введите текст'
                      }
                      className={`card-input mb-2 ${
                        isNotEmpty(errors)
                          ? errors[`${item.name}_en`]
                            ? 'border-danger'
                            : 'border-success'
                          : 'border-1'
                      }`}
                    />
                  )}
                />
              </div>
            </div>
          );
        }
        return (
          <div key={index} className={'w-100'}>
            <div className={'d-flex justify-content-between'}>
              <p className={`m-0 text-white text-uppercase`}>{item.label}</p>
              <p
                className={'m-0 align-self-end text-danger'}
                style={{ height: '1.5rem' }}
              >
                {errors?.[item.name] ? displayTextError(errors[item.name]) : ''}
              </p>
            </div>

            <Controller
              name={item.name}
              control={control}
              rules={{
                required: true,
                maxLength: 100,
                validate: {
                  notEmpty: (v) => String(v).trim().length > 0,
                },
              }}
              render={({ field }) => (
                <Input
                  {...field}
                  placeholder={
                    item.type === 'number' ? 'Введите число' : 'Введите текст'
                  }
                  className={`text-white round mb-2 ${
                    isNotEmpty(errors)
                      ? errors[item.name]
                        ? 'border-danger'
                        : 'border-success'
                      : 'border-1'
                  }`}
                />
              )}
            />
          </div>
        );
      })}
    </>
  );
};
