import { TableBasic } from './table/Table';
import { NavLink } from 'react-router-dom';
import { info } from '@src/assets/images/svg.js';

export const InfoGeneral = (props) => {
  const { path } = props.match;

  if (path.includes('users'))
    return (
      <div className={'d-flex gap-2'}>
        <nav
          className={'d-flex flex-column align-items-start p-1 pb-0 w-25'}
          style={{
            background: 'rgba(255, 255, 255, 0.1)',
            borderRadius: '.5rem',
          }}
        >
          <NavLink
            activeStyle={{ color: '#FFF' }}
            to={`/users/plants`}
            className={'btn border-0 p-0 mb-50'}
          >
            <span className={'me-1'}>{info}</span>
            <span>{'База растений'}</span>
          </NavLink>

          <NavLink
            activeStyle={{ color: '#FFF' }}
            to={`/users/pests`}
            className={'btn p-0'}
          >
            <span className={'me-1'}>{info}</span>
            <span>{'База вредителей (болезней)'}</span>
          </NavLink>
        </nav>
        <div className={'w-100'}>
          <TableBasic />
        </div>
      </div>
    );

  return <TableBasic />;
};
