import { Button } from "reactstrap";

export const DeleteModalBody = ({ type, plantName }) => {
  return (
    <div className={'text-center pb-2'}>
      <h3 className={'mb-2'}>Внимание!</h3>
      <h4>
        Вы хотите удалить {type}{' '}
        <span className={'text-success'}>«{plantName}»</span>?
      </h4>
    </div>
  );
};
export const DeleteModalFooter = ({ onCancel, onDelete }) => {
  return (
    <div className="text-center pt-2 pb-3">
      <h4 className="mb-2">Подтвердить удаление?</h4>
      <div>
        <Button onClick={onCancel} type={'button'} outline className={'me-4 br-10'}>
          Нет
        </Button>
        <Button onClick={onDelete} type={'button'} color={'success'} className={'br-10'}>
          Да
        </Button>
      </div>
    </div>
  );
};
