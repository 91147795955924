import {noNetConnection} from "./constants";

export const getQueryData = (querySnapshot, options, setLoading, setData) => {
	const dataArr = []
	querySnapshot.forEach(doc => {
		getTransformedArr(doc, dataArr, options)
	})
	
	setData(() => {
		setLoading(false)
		return dataArr;
	})
}

export const getDocData = (doc, options, setLoading, setData) => {
	setData(() => {
		setLoading(false)
		return getTransformedObj(doc, options)
	})
}

function getTransformedArr(doc, dataArr, options) {
	const {idField, transform} = options
	let dataObj = {}
	
	idField && (dataObj[idField] = doc.id)
	
	transform
		? dataArr.push({...transform(doc.data()), ...dataObj})
		: dataArr.push({...dataObj, ...doc.data()})
	
	return dataArr
}

function getTransformedObj(doc, options) {
	let dataObj = {}
	const {idField, transform} = options
	
	idField && (dataObj[idField] = doc.id)
	
	transform
		? dataObj = {...transform(doc.data()), ...dataObj}
		: dataObj = {...dataObj, ...doc.data()}
	
	return dataObj
}

export const tryBatchCommit = (batch, onSaveMessage, onErrorMessage) =>
	batch.commit()
		.then(() => onSaveMessage())
		.catch(err => onErrorMessage(err));

export const checkNetConnection = res => {
	if (res?.metadata.fromCache) throw new Error(noNetConnection.message)
}

export const withCheckNet = promise =>
	promise
		.then(res => {
			checkNetConnection(res)
			return res
		})
